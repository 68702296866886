import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/Swapmurai.png";
import projImg2 from "../assets/img/ninjadrop.png";
import projImg3 from "../assets/img/opentea.png";
import projImg4 from "../assets/img/bigpadsale.png";
import projImg5 from "../assets/img/metamask.png";
import projImg6 from "../assets/img/bears.png";
import projImg7 from "../assets/img/oxsend.png";
import projImg8 from "../assets/img/postake.png";
import projImg9 from "../assets/img/mytoken.png";
import projImg10 from "../assets/img/xplorer1.png";
import projImg11 from "../assets/img/faucet.png";
import projImg12 from "../assets/img/bridge.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import colorSharp from "../assets/img/color-sharp.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { href } from 'react-router-dom'
import React from 'react';

export const Projects = () => {

  const projects = [
    {
      title: "Scaninja",
      description: "Ninjachain Block explorer",
      imgUrl: projImg10,
    },
    {
      title: "Swapmurai",
      description: "1 AMM on Ninjachain",
      imgUrl: projImg1,
    },
    {
      title: "Bigpadsale",
      description: "Low fees Multichain launcpad platform",
      imgUrl: projImg4,
    },
    {
      title: "NINJA Faucet",
      description: "Get NINJA faucet here",
      imgUrl: projImg11,
      href: "https://exchange.mozartfinance.io",
    },
    {
      title: "OpenTea",
      description: "NFT Marketplaces - Discover, collect, and sell extraordinary NFTs",
      imgUrl: projImg3,
    },
    {
      title: "Ninjachain bridge",
      description: "Bridge to Ninjachain",
      imgUrl: projImg12,
    },
    {
      title: "0xsend",
      description: "0xSend - Multichain Sending Tokens to Multiple Addresses.",
      imgUrl: projImg7,
    },
    {
      title: "Postake",
      description: "Postake - Stake $NINJA on POS POOL with high apy%.",
      imgUrl: projImg8,
    },
    {
      title: "Mytoken",
      description: "My Token - Instant issuing your own token with one click.",
      imgUrl: projImg9,
    },
    {
      title: "Dollar Bears",
      description: "Dollar Bears - High return USD",
      imgUrl: projImg6,
    },
    {
      title: "MetaMask",
      description: "MetaMask is the leading self-custodial wallet",
      imgUrl: projImg5,
      href: "https://exchange.mozartfinance.io",
    },
    {
      title: "Ninjadrop",
      description: "Airdrop Ninjachain",
      imgUrl: projImg2,
    }

    
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
              <p>
          </p>
          <p>
          </p>
           <p>
          </p>
          <p>
          </p>
                <h2> </h2>
                <p>EVM has the most reliable ecosystem for creating dApps and is the network of choice for Web3 projects. It enables users to transact with the multiple tokens that run on the Layer 1 or layer 2 blockchain and lets Layer 3 scaling solutions connect to it to benefit from its underlying security. In Ninjachain layer 3, We set the base price for NINJA at 0.0045 USDT. In transactions on Ninjachain the maximum value of the gas price is 1.6 gwei. This means that every transaction on Ninjachain is subject to a gas fee of 0.0045 USDT / 160,000 = 0.000000028 USDT. Of course it will fluctuate but still it's a very good price.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                 <a href="https://docs.ninjachain.xyz/"> 
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    
                     <Nav.Link eventKey="first">Integrated project Ninjachain</Nav.Link>
                    
                    
                  </Nav></a>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
      <img className="background-image-left" src={colorSharp}></img>
    </section>
  )
}
