import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/3d.gif";

import navIcon3 from "../assets/img/images.png";
import navIcon4 from "../assets/img/twitter3.png";
import navIcon5 from "../assets/img/application1.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import colorSharp from "../assets/img/color-sharp.png";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={10} sm={5}>
          <p>
          </p>
          <p>
          </p>
            <img src={logo} alt="Logo" />
          </Col>

               

          <Col size={16} sm={9} className="text-center text-sm-end">
            <div className="social-icon" >
              <a href="https://twitter.com/Ninjachain_L3"><img src={navIcon4} alt="Icon" /></a>
              <a href="https://t.me/Ninjachain_layer3"><img src={navIcon5} alt="Icon" /></a>
              <a href="https://github.com/ninjachain-layer3"><img src={navIcon3} alt="Icon" /></a>
              
            </div>
            <br>
            </br>
            <p>Copyright 2024 Ninjalabs All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
      <img className="background-image-left" src={colorSharp}></img>
    </footer>
  )
}
